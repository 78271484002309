// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.local-dev.ts` when running "ng serve".
// The list of file replacements can be found in `angular.json`.

declare global {
    interface Window {
        env?: {
            production?: string;
            AUTH_SERVER_URL?: string;
            AUTH_REALM?: string;
            AUTH_CLIENT?: string;
            AUTH_SERVER_PROFILE_URL?: string;
            URI_APP_BACKEND?: string;
        };
    }
}

// on dwin-prod the environment variables will override this configuration
// but since this is using envsubst it is not feasible to use for local development
// so we need to have a local-dev environment configuration (environment.local-dev.ts) which will be selected by
// the angular.json per default for "ng serve" command
export const environment = {
    production: window?.env?.production === 'true',
    AUTH_SERVER_URL: window.env?.AUTH_SERVER_URL || 'https://auth.distel-digital.de/auth/',
    AUTH_REALM: window.env?.AUTH_REALM || 'dwin',
    AUTH_CLIENT: window.env?.AUTH_CLIENT || 'dwin_dev',
    AUTH_SERVER_PROFILE_URL:
        window.env?.AUTH_SERVER_PROFILE_URL || 'https://auth.distel-digital.de/auth/realms/dwin/account',
    URI_APP_BACKEND: window.env?.URI_APP_BACKEND || 'https://app-backend-api.dwin.health/graphql',
};
