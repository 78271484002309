import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Any: any;
    Date: any;
    Long: any;
};

export type AllPublishedStateValues = {
    __typename?: 'AllPublishedStateValues';
    errorMsg?: Maybe<Scalars['String']>;
    values?: Maybe<Array<StateValueWithId>>;
};

export type Area = {
    __typename?: 'Area';
    area?: Maybe<Array<Area>>;
    categories?: Maybe<Array<Category>>;
    functions?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    overview?: Maybe<Scalars['String']>;
};

export type BuddySessionSummaryFeedEvent = FeedEventI & {
    __typename?: 'BuddySessionSummaryFeedEvent';
    chatId: Scalars['String'];
    feedEventType: FeedEventType;
    summary: Scalars['String'];
    timestamp: Scalars['String'];
};

export type CalculatedStateValue = IStateValue & {
    __typename?: 'CalculatedStateValue';
    expiresAt?: Maybe<Scalars['Int']>;
    state: State;
    stateDependencies?: Maybe<Array<StateDependency>>;
    timestamp?: Maybe<Scalars['String']>;
    value: Scalars['String'];
};

export type CalculationInput = {
    calcId: Scalars['String'];
    stateValues: Array<StateValueInput>;
};

export type CalculationStateParameter = {
    __typename?: 'CalculationStateParameter';
    stateId: Scalars['String'];
};

export type CalculationStateParameterInput = {
    stateId: Scalars['String'];
};

export type CalculationType = 'DEFAULT' | 'RISK';

export type Category = {
    __typename?: 'Category';
    area?: Maybe<Array<Scalars['String']>>;
    description: Scalars['String'];
    groupValuesByCategory?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
};

export type CheckItemTask = ITask & {
    __typename?: 'CheckItemTask';
    checkItemTemplates: Array<CheckItemTemplate>;
    createdAt: Scalars['String'];
    description: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
    note?: Maybe<Scalars['String']>;
    references?: Maybe<Array<Reference>>;
    sortKey?: Maybe<Scalars['Int']>;
    taskStateValues: Array<TaskStateValue>;
    taskType: TaskType;
    updatedAt: Scalars['String'];
};

export type CheckItemTemplate = {
    __typename?: 'CheckItemTemplate';
    checked?: Maybe<Scalars['Boolean']>;
    description?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    mandatory?: Maybe<Scalars['Boolean']>;
    order?: Maybe<Scalars['Int']>;
    title: Scalars['String'];
};

export type CheckItemTemplateInput = {
    checked: Scalars['Boolean'];
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['String']>;
    mandatory: Scalars['Boolean'];
    order: Scalars['Int'];
    title: Scalars['String'];
};

export type CourseInfo = {
    __typename?: 'CourseInfo';
    description: Scalars['String'];
    lessons: Array<LessonInfo>;
    title: Scalars['String'];
};

export type DataType = 'DATE' | 'NUMERIC' | 'SELECTION' | 'STRING';

export type DefinedStateValue = IStateValue & {
    __typename?: 'DefinedStateValue';
    expiresAt?: Maybe<Scalars['Int']>;
    state: State;
    timestamp?: Maybe<Scalars['String']>;
    value: Scalars['String'];
};

export type DeliverPolicy = 'LAST_PER_SUBJECT' | 'NEW';

export type DescriptionBlock = {
    __typename?: 'DescriptionBlock';
    description: Scalars['String'];
    title: Scalars['String'];
};

export type DescriptionBlockInput = {
    description: Scalars['String'];
    title: Scalars['String'];
};

export type DwinCreatedFeedEvent = FeedEventI & {
    __typename?: 'DwinCreatedFeedEvent';
    feedEventType: FeedEventType;
    timestamp: Scalars['String'];
};

export type EffectType = 'NEGATIVE' | 'NEUTRAL' | 'POSITIVE';

export type EffectTypeText = {
    __typename?: 'EffectTypeText';
    negative?: Maybe<Scalars['String']>;
    neutral?: Maybe<Scalars['String']>;
    positive?: Maybe<Scalars['String']>;
};

export type EffectTypeTextInput = {
    negative?: InputMaybe<Scalars['String']>;
    neutral?: InputMaybe<Scalars['String']>;
    positive?: InputMaybe<Scalars['String']>;
};

export type FeedEvent =
    | BuddySessionSummaryFeedEvent
    | DwinCreatedFeedEvent
    | ProjectCancelledFeedEvent
    | ProjectFinishedFeedEvent
    | StateValueCalculatedFeedEvent
    | StateValueDefinedFeedEvent;

export type FeedEventI = {
    feedEventType: FeedEventType;
    timestamp: Scalars['String'];
};

export type FeedEventType =
    | 'BUDDY_SESSION_SUMMARY'
    | 'DWIN_CREATED'
    | 'PROJECT_CANCELLED'
    | 'PROJECT_FINISHED'
    | 'STATE_VALUE_CALCULATED'
    | 'STATE_VALUE_DEFINED';

export type FilterCriteria = {
    __typename?: 'FilterCriteria';
    operator: Operator;
    stateId: Scalars['String'];
    value: Scalars['String'];
};

export type FilterCriteriaInput = {
    operator: Operator;
    stateId: Scalars['String'];
    value: Scalars['String'];
};

export type IStateValue = {
    expiresAt?: Maybe<Scalars['Int']>;
    state: State;
    timestamp?: Maybe<Scalars['String']>;
    value: Scalars['String'];
};

export type ITask = {
    createdAt: Scalars['String'];
    description: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
    note?: Maybe<Scalars['String']>;
    references?: Maybe<Array<Reference>>;
    sortKey?: Maybe<Scalars['Int']>;
    taskStateValues: Array<TaskStateValue>;
    taskType: TaskType;
    updatedAt: Scalars['String'];
};

export type Image = {
    __typename?: 'Image';
    type: ImageType;
    url: Scalars['String'];
};

export type ImageType = 'animation' | 'image';

export type KnownAndUnknownStateValues = {
    __typename?: 'KnownAndUnknownStateValues';
    knownStateValues?: Maybe<Array<Maybe<StateValue>>>;
    unknownStateValueStates?: Maybe<Array<Maybe<State>>>;
};

export type KnownAndUnknownStateValuesFilter = {
    categories?: InputMaybe<Array<Scalars['String']>>;
};

export type LearningTask = ITask & {
    __typename?: 'LearningTask';
    createdAt: Scalars['String'];
    description: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
    note?: Maybe<Scalars['String']>;
    references?: Maybe<Array<Reference>>;
    sortKey?: Maybe<Scalars['Int']>;
    taskStateValues: Array<TaskStateValue>;
    taskType: TaskType;
    updatedAt: Scalars['String'];
    xapiCourseStructure: XapiCourseStructure;
    xapiCourseUploadInfo?: Maybe<XapiCourseUploadInfo>;
};

export type LessonInfo = {
    __typename?: 'LessonInfo';
    activityId: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    isFinished: Scalars['Boolean'];
    ordinal: Scalars['Int'];
    title: Scalars['String'];
    uuid: Scalars['String'];
};

export type Mutation = {
    __typename?: 'Mutation';
    addState: State;
    addStateValue: Scalars['Boolean'];
    addStateValues: AllPublishedStateValues;
    addTaskToProjectTemplate?: Maybe<ProjectTemplate>;
    calculateStateValue: AllPublishedStateValues;
    cancelUserProject: UserProject;
    /** @deprecated No longer supported */
    createTask: ITask;
    deleteTask?: Maybe<Scalars['Boolean']>;
    importStates: Scalars['Boolean'];
    learningTaskUploadUrl: Scalars['String'];
    mapUploadedXapiCourseZipToLearningTask: ITask;
    projectGroups: Array<ProjectGroup>;
    /** use mutation project instand */
    projectTemplate: ProjectTemplate;
    projectTemplateStatus: Scalars['Boolean'];
    startProject: UserProject;
    startProjectByIdPrefix: UserProject;
    task: ITask;
    updateState: State;
    updateUserCheckItems: Scalars['Boolean'];
    userTaskResultUpdate?: Maybe<UserTask>;
};

export type MutationAddStateArgs = {
    stateInput: StateInput;
};

export type MutationAddStateValueArgs = {
    stateValueInput: StateValueInput;
};

export type MutationAddStateValuesArgs = {
    stateValueInputs: Array<StateValueInput>;
};

export type MutationAddTaskToProjectTemplateArgs = {
    projectId: Scalars['String'];
    taskId: Scalars['String'];
};

export type MutationCalculateStateValueArgs = {
    calculationInput: CalculationInput;
};

export type MutationCancelUserProjectArgs = {
    userProjectId: Scalars['String'];
};

export type MutationCreateTaskArgs = {
    input: TaskInput;
};

export type MutationDeleteTaskArgs = {
    taskId: Scalars['String'];
};

export type MutationImportStatesArgs = {
    stateExport: Array<StateExportInput>;
};

export type MutationMapUploadedXapiCourseZipToLearningTaskArgs = {
    taskId: Scalars['ID'];
    zipFileName: Scalars['String'];
};

export type MutationProjectGroupsArgs = {
    input: Array<ProjectGroupInput>;
};

export type MutationProjectTemplateArgs = {
    input: ProjectTemplateInput;
};

export type MutationProjectTemplateStatusArgs = {
    id: Scalars['ID'];
    status: ProjectTemplateStatus;
};

export type MutationStartProjectArgs = {
    projectId: Scalars['String'];
};

export type MutationStartProjectByIdPrefixArgs = {
    projectIdPrefix: Scalars['String'];
};

export type MutationTaskArgs = {
    input: TaskInput;
};

export type MutationUpdateStateArgs = {
    stateInput: StateUpdateInput;
};

export type MutationUpdateUserCheckItemsArgs = {
    checkItemUpdates: Array<UserCheckItemInput>;
    userTaskId: Scalars['String'];
};

export type MutationUserTaskResultUpdateArgs = {
    result: Array<UserTaskResultInput>;
    userTaskId: Scalars['String'];
};

export type NavigationNode = {
    __typename?: 'NavigationNode';
    clickable?: Maybe<Scalars['Boolean']>;
    description?: Maybe<Array<DescriptionBlock>>;
    groupValuesByCategory?: Maybe<Scalars['Boolean']>;
    images?: Maybe<Array<Image>>;
    name: Scalars['String'];
    nodes?: Maybe<Array<NavigationNode>>;
    order?: Maybe<Scalars['Int']>;
    type: NavigationNodeType;
};

export type NavigationNodeType = 'area' | 'category';

export type Operator = 'BIGGER_THAN' | 'EQUALS' | 'EXIST' | 'NOT_EQUALS' | 'SMALLER_THAN';

export type PageInfo = {
    __typename?: 'PageInfo';
    /** Count per Page */
    count: Scalars['Int'];
    /** Current Page */
    page: Scalars['Int'];
    /** Max count of available Pages by same count */
    pages: Scalars['Int'];
    /** Overall result count */
    totalCount: Scalars['Int'];
};

export type PaginationParams = {
    count?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
};

export type Project = {
    __typename?: 'Project';
    durationMinutes?: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
    name: Scalars['String'];
    projectObjective?: Maybe<Scalars['String']>;
    tasks?: Maybe<Array<Maybe<ITask>>>;
};

export type ProjectCancelledFeedEvent = FeedEventI & {
    __typename?: 'ProjectCancelledFeedEvent';
    feedEventType: FeedEventType;
    projectId: Scalars['String'];
    projectTitle: Scalars['String'];
    timestamp: Scalars['String'];
    userProjectId: Scalars['String'];
};

export type ProjectFinishedFeedEvent = FeedEventI & {
    __typename?: 'ProjectFinishedFeedEvent';
    feedEventType: FeedEventType;
    projectId: Scalars['String'];
    projectTitle: Scalars['String'];
    timestamp: Scalars['String'];
    userProjectId: Scalars['String'];
};

export type ProjectGroup = {
    __typename?: 'ProjectGroup';
    id: Scalars['String'];
    name: Scalars['String'];
};

export type ProjectGroupInput = {
    id: Scalars['String'];
    name: Scalars['String'];
};

export type ProjectGroupProjectsPagination = {
    count?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    projectGroupId: Scalars['String'];
};

export type ProjectTemplate = {
    __typename?: 'ProjectTemplate';
    createdAt?: Maybe<Scalars['String']>;
    creator: Scalars['String'];
    durationMinutes?: Maybe<Scalars['Int']>;
    filterCriteria?: Maybe<Array<Array<FilterCriteria>>>;
    id: Scalars['ID'];
    name: Scalars['String'];
    note?: Maybe<Scalars['String']>;
    projectGroups: Array<ProjectGroup>;
    projectObjective: Scalars['String'];
    recommendationCriteria?: Maybe<Array<Array<FilterCriteria>>>;
    status?: Maybe<ProjectTemplateStatus>;
    targetStateValues: Array<TargetStateValue>;
    tasks: Array<ITask>;
    updatedAt?: Maybe<Scalars['String']>;
};

export type ProjectTemplateInput = {
    durationMinutes?: InputMaybe<Scalars['Int']>;
    filterCriteria: Array<Array<FilterCriteriaInput>>;
    id?: InputMaybe<Scalars['String']>;
    name: Scalars['String'];
    note?: InputMaybe<Scalars['String']>;
    projectGroups: Array<Scalars['String']>;
    projectObjective: Scalars['String'];
    recommendationCriteria?: InputMaybe<Array<Array<FilterCriteriaInput>>>;
    targetStateValues: Array<TargetStateValueInput>;
    tasks: Array<Scalars['ID']>;
};

export type ProjectTemplatePage = {
    __typename?: 'ProjectTemplatePage';
    entities: Array<ProjectTemplate>;
    pageInfo: PageInfo;
};

export type ProjectTemplateStatus = 'DELETED' | 'DRAFT' | 'PUBLISHED';

export type ProjectTemplatesFilter = {
    id?: InputMaybe<Scalars['ID']>;
    search?: InputMaybe<Scalars['String']>;
};

export type ProjectsPaginated = {
    __typename?: 'ProjectsPaginated';
    pageInfo: PageInfo;
    projects: Array<Project>;
};

/** TODO: Add from/until filter option. */
export type Query = {
    __typename?: 'Query';
    /** @deprecated No longer supported */
    areas: Array<Area>;
    categories: Array<Category>;
    /**
     * Return all stateDependencies from Calculated Type.
     * Solve Calculation <=> Calculation Dependencies
     */
    definedDependenciesStateValuesFromCalculation: Array<DefinedStateValue>;
    definedStateDependencies: Array<Maybe<State>>;
    feedEvents: Array<FeedEvent>;
    knownAndUnknownStateValues?: Maybe<KnownAndUnknownStateValues>;
    learningTaskCourseInfo: CourseInfo;
    learningTaskLaunchUrl: Scalars['String'];
    navigationNodes: Array<NavigationNode>;
    project?: Maybe<Project>;
    projectGroups: Array<ProjectGroup>;
    projectSuggestions: UserSuggestion;
    projectTemplateById: ProjectTemplate;
    projectTemplatesPage: ProjectTemplatePage;
    requiredActions: Array<Maybe<RequiredAction>>;
    root: Scalars['String'];
    startedUserCalculations: Array<Maybe<StartedUserCalculation>>;
    state: State;
    stateCalculationUsages: Array<Maybe<State>>;
    stateExport: Array<Maybe<StateExport>>;
    statePage: StateResult;
    stateValues: Array<StateValue>;
    /** @deprecated No longer supported */
    states: Array<State>;
    taskPage: TasksPage;
    tasks: Array<ITask>;
    units: Array<Unit>;
    userProject?: Maybe<UserProject>;
    userProjects: UserProjectResult;
};

/** TODO: Add from/until filter option. */
export type QueryDefinedDependenciesStateValuesFromCalculationArgs = {
    calcStateId: Scalars['String'];
    returnEmptyValuesIfNotSet?: InputMaybe<Scalars['Boolean']>;
};

/** TODO: Add from/until filter option. */
export type QueryDefinedStateDependenciesArgs = {
    stateId: Scalars['String'];
};

/** TODO: Add from/until filter option. */
export type QueryKnownAndUnknownStateValuesArgs = {
    filter?: InputMaybe<KnownAndUnknownStateValuesFilter>;
};

/** TODO: Add from/until filter option. */
export type QueryLearningTaskCourseInfoArgs = {
    userTaskId: Scalars['String'];
};

/** TODO: Add from/until filter option. */
export type QueryLearningTaskLaunchUrlArgs = {
    userTaskId: Scalars['String'];
};

/** TODO: Add from/until filter option. */
export type QueryProjectArgs = {
    projectId: Scalars['String'];
};

/** TODO: Add from/until filter option. */
export type QueryProjectSuggestionsArgs = {
    input?: InputMaybe<UserSuggestionInput>;
};

/** TODO: Add from/until filter option. */
export type QueryProjectTemplateByIdArgs = {
    id?: InputMaybe<Scalars['String']>;
};

/** TODO: Add from/until filter option. */
export type QueryProjectTemplatesPageArgs = {
    count?: InputMaybe<Scalars['Int']>;
    filter?: InputMaybe<ProjectTemplatesFilter>;
    page?: InputMaybe<Scalars['Int']>;
};

/** TODO: Add from/until filter option. */
export type QueryStateArgs = {
    stateId: Scalars['String'];
};

/** TODO: Add from/until filter option. */
export type QueryStateCalculationUsagesArgs = {
    stateId: Scalars['String'];
};

/** TODO: Add from/until filter option. */
export type QueryStatePageArgs = {
    count?: InputMaybe<Scalars['Int']>;
    filter?: InputMaybe<StateFilter>;
    page?: InputMaybe<Scalars['Int']>;
};

/** TODO: Add from/until filter option. */
export type QueryStateValuesArgs = {
    filter?: InputMaybe<StateValueFilter>;
};

/** TODO: Add from/until filter option. */
export type QueryStatesArgs = {
    filter?: InputMaybe<StateFilter>;
};

/** TODO: Add from/until filter option. */
export type QueryTaskPageArgs = {
    count?: InputMaybe<Scalars['Int']>;
    filter?: InputMaybe<TaskFilter>;
    page?: InputMaybe<Scalars['Int']>;
};

/** TODO: Add from/until filter option. */
export type QueryTasksArgs = {
    filter?: InputMaybe<TaskFilter>;
};

/** TODO: Add from/until filter option. */
export type QueryUnitsArgs = {
    search?: InputMaybe<Scalars['String']>;
};

/** TODO: Add from/until filter option. */
export type QueryUserProjectArgs = {
    userProjectId: Scalars['String'];
};

/** TODO: Add from/until filter option. */
export type QueryUserProjectsArgs = {
    count?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    sorting?: InputMaybe<UserProjectSorting>;
    userProjectStateFilter?: InputMaybe<UserProjectStateFilter>;
};

export type Reference = {
    __typename?: 'Reference';
    description?: Maybe<Scalars['String']>;
    /** @deprecated No longer supported */
    introduction?: Maybe<Scalars['String']>;
    referenceType: ReferenceType;
};

export type ReferenceInput = {
    description?: InputMaybe<Scalars['String']>;
    referenceType: ReferenceType;
};

export type ReferenceType = 'DISCLAIMER' | 'HINT' | 'SOURCE';

export type RequiredAction = {
    __typename?: 'RequiredAction';
    requiredActionType: RequiredActionType;
    stateValues: Array<Maybe<DefinedStateValue>>;
};

export type RequiredActionType = 'BuddyDisclaimer' | 'StateCollection';

export type Role = 'PROJECT_R' | 'PROJECT_W' | 'STATE_R' | 'STATE_W';

export type SortOrder = 'ASC' | 'DESC';

export type StartedUserCalculation = {
    __typename?: 'StartedUserCalculation';
    calcId: Scalars['String'];
    calcName: Scalars['String'];
    categories?: Maybe<Array<Maybe<Scalars['String']>>>;
    timestamp: Scalars['Int'];
};

/** All possible Statetypes. Every value of this union implements StateI */
export type State = StateDate | StateNumeric | StateSelection | StateString;

export type StateCollectionTask = ITask & {
    __typename?: 'StateCollectionTask';
    createdAt: Scalars['String'];
    description: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
    note?: Maybe<Scalars['String']>;
    references?: Maybe<Array<Reference>>;
    sortKey?: Maybe<Scalars['Int']>;
    taskStateValues: Array<TaskStateValue>;
    taskType: TaskType;
    updatedAt: Scalars['String'];
};

export type StateDate = StateI & {
    __typename?: 'StateDate';
    categories: Array<Category>;
    dataType: DataType;
    defaultQuestion: Scalars['String'];
    description: Array<DescriptionBlock>;
    effectTypeText?: Maybe<EffectTypeText>;
    id: Scalars['String'];
    isSubscore?: Maybe<Scalars['Boolean']>;
    lifeTimeMs?: Maybe<Scalars['Long']>;
    name: Scalars['String'];
    references?: Maybe<Array<Reference>>;
    stateDependencies?: Maybe<Array<State>>;
    type: StateType;
    visibleAtCategory: Scalars['Boolean'];
    visibleAtDiary?: Maybe<Scalars['Boolean']>;
};

export type StateDependency = {
    __typename?: 'StateDependency';
    effectType: EffectType;
    stateValue: StateValue;
};

export type StateExport = {
    __typename?: 'StateExport';
    calculationType?: Maybe<CalculationType>;
    categories?: Maybe<Array<Maybe<Scalars['String']>>>;
    dataType?: Maybe<DataType>;
    defaultQuestion?: Maybe<Scalars['String']>;
    description?: Maybe<Array<Maybe<DescriptionBlock>>>;
    effectTypeText?: Maybe<EffectTypeText>;
    id?: Maybe<Scalars['String']>;
    isSubscore?: Maybe<Scalars['Boolean']>;
    lifeTimeMs?: Maybe<Scalars['Float']>;
    name?: Maybe<Scalars['String']>;
    references?: Maybe<Array<Maybe<Reference>>>;
    selectionOptions?: Maybe<Array<Maybe<StateSelectionOption>>>;
    stateDependencies?: Maybe<Array<Maybe<CalculationStateParameter>>>;
    type?: Maybe<StateType>;
    unit?: Maybe<Scalars['String']>;
    valueRange?: Maybe<ValueRange>;
    visibleAtCategory?: Maybe<Scalars['Boolean']>;
    visibleAtDiary?: Maybe<Scalars['Boolean']>;
};

export type StateExportInput = {
    calculationType?: InputMaybe<CalculationType>;
    categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dataType?: InputMaybe<DataType>;
    defaultQuestion?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Array<InputMaybe<DescriptionBlockInput>>>;
    effectTypeText?: InputMaybe<EffectTypeTextInput>;
    id?: InputMaybe<Scalars['String']>;
    isSubscore?: InputMaybe<Scalars['Boolean']>;
    lifeTimeMs?: InputMaybe<Scalars['Float']>;
    name?: InputMaybe<Scalars['String']>;
    references?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
    selectionOptions?: InputMaybe<Array<InputMaybe<StateSelectionOptionInput>>>;
    stateDependencies?: InputMaybe<Array<InputMaybe<CalculationStateParameterInput>>>;
    type?: InputMaybe<StateType>;
    unit?: InputMaybe<Scalars['String']>;
    valueRange?: InputMaybe<ValueRangeInput>;
    visibleAtCategory?: InputMaybe<Scalars['Boolean']>;
    visibleAtDiary?: InputMaybe<Scalars['Boolean']>;
};

export type StateFilter = {
    category?: InputMaybe<Scalars['String']>;
    dataType?: InputMaybe<DataType>;
    /** exact state ids are needed */
    ids?: InputMaybe<Array<Scalars['String']>>;
    /** Searching for matching values (names, stateIds, categories, ...) - especially substrings */
    search?: InputMaybe<Scalars['String']>;
    stateType?: InputMaybe<StateType>;
    visibleAtCategory?: InputMaybe<Scalars['Boolean']>;
    visibleAtDiary?: InputMaybe<Scalars['Boolean']>;
};

export type StateI = {
    categories: Array<Category>;
    dataType: DataType;
    defaultQuestion: Scalars['String'];
    description: Array<DescriptionBlock>;
    effectTypeText?: Maybe<EffectTypeText>;
    id: Scalars['String'];
    isSubscore?: Maybe<Scalars['Boolean']>;
    lifeTimeMs?: Maybe<Scalars['Long']>;
    name: Scalars['String'];
    references?: Maybe<Array<Reference>>;
    stateDependencies?: Maybe<Array<State>>;
    type: StateType;
    visibleAtCategory: Scalars['Boolean'];
    visibleAtDiary?: Maybe<Scalars['Boolean']>;
};

export type StateInput = {
    /** Ids of mapped categories */
    categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dataType: DataType;
    defaultQuestion: Scalars['String'];
    description: Array<DescriptionBlockInput>;
    effectTypeText?: InputMaybe<EffectTypeTextInput>;
    id: Scalars['String'];
    isSubscore?: InputMaybe<Scalars['Boolean']>;
    lifeTimeMs?: InputMaybe<Scalars['Long']>;
    name: Scalars['String'];
    references?: InputMaybe<Array<ReferenceInput>>;
    selectionOptions?: InputMaybe<Array<StateSelectionOptionInput>>;
    type: StateType;
    unit?: InputMaybe<Scalars['String']>;
    valueRange?: InputMaybe<ValueRangeInput>;
    visibleAtCategory: Scalars['Boolean'];
    visibleAtDiary?: InputMaybe<Scalars['Boolean']>;
};

export type StateNumeric = StateI & {
    __typename?: 'StateNumeric';
    categories: Array<Category>;
    dataType: DataType;
    defaultQuestion: Scalars['String'];
    description: Array<DescriptionBlock>;
    effectTypeText?: Maybe<EffectTypeText>;
    id: Scalars['String'];
    isSubscore?: Maybe<Scalars['Boolean']>;
    lifeTimeMs?: Maybe<Scalars['Long']>;
    name: Scalars['String'];
    references?: Maybe<Array<Reference>>;
    stateDependencies?: Maybe<Array<State>>;
    type: StateType;
    unit?: Maybe<Unit>;
    valueRange?: Maybe<ValueRange>;
    visibleAtCategory: Scalars['Boolean'];
    visibleAtDiary?: Maybe<Scalars['Boolean']>;
};

export type StateResult = {
    __typename?: 'StateResult';
    entities: Array<State>;
    pageInfo: PageInfo;
};

export type StateSelection = StateI & {
    __typename?: 'StateSelection';
    categories: Array<Category>;
    dataType: DataType;
    defaultQuestion: Scalars['String'];
    description: Array<DescriptionBlock>;
    effectTypeText?: Maybe<EffectTypeText>;
    id: Scalars['String'];
    isSubscore?: Maybe<Scalars['Boolean']>;
    lifeTimeMs?: Maybe<Scalars['Long']>;
    name: Scalars['String'];
    references?: Maybe<Array<Reference>>;
    selectionOptions: Array<StateSelectionOption>;
    stateDependencies?: Maybe<Array<State>>;
    type: StateType;
    visibleAtCategory: Scalars['Boolean'];
    visibleAtDiary?: Maybe<Scalars['Boolean']>;
};

export type StateSelectionOption = {
    __typename?: 'StateSelectionOption';
    id: Scalars['String'];
    name: Scalars['String'];
};

export type StateSelectionOptionInput = {
    id: Scalars['String'];
    name: Scalars['String'];
};

export type StateString = StateI & {
    __typename?: 'StateString';
    categories: Array<Category>;
    dataType: DataType;
    defaultQuestion: Scalars['String'];
    description: Array<DescriptionBlock>;
    effectTypeText?: Maybe<EffectTypeText>;
    id: Scalars['String'];
    isSubscore?: Maybe<Scalars['Boolean']>;
    lifeTimeMs?: Maybe<Scalars['Long']>;
    name: Scalars['String'];
    references?: Maybe<Array<Reference>>;
    stateDependencies?: Maybe<Array<State>>;
    type: StateType;
    visibleAtCategory: Scalars['Boolean'];
    visibleAtDiary?: Maybe<Scalars['Boolean']>;
};

export type StateType = 'CALCULATED' | 'DEFINED';

export type StateUpdateInput = {
    /** Ids of mapped categories */
    categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dataType?: InputMaybe<DataType>;
    defaultQuestion?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Array<DescriptionBlockInput>>;
    effectTypeText?: InputMaybe<EffectTypeTextInput>;
    id: Scalars['String'];
    isSubscore?: InputMaybe<Scalars['Boolean']>;
    lifeTimeMs?: InputMaybe<Scalars['Long']>;
    name?: InputMaybe<Scalars['String']>;
    references?: InputMaybe<Array<ReferenceInput>>;
    selectionOptions?: InputMaybe<Array<StateSelectionOptionInput>>;
    type?: InputMaybe<StateType>;
    unit?: InputMaybe<Scalars['String']>;
    valueRange?: InputMaybe<ValueRangeInput>;
    visibleAtCategory?: InputMaybe<Scalars['Boolean']>;
    visibleAtDiary?: InputMaybe<Scalars['Boolean']>;
};

export type StateValue = CalculatedStateValue | DefinedStateValue;

export type StateValueCalculatedFeedEvent = FeedEventI & {
    __typename?: 'StateValueCalculatedFeedEvent';
    feedEventType: FeedEventType;
    state: State;
    stateValue: Scalars['String'];
    timestamp: Scalars['String'];
};

export type StateValueDefinedFeedEvent = FeedEventI & {
    __typename?: 'StateValueDefinedFeedEvent';
    feedEventType: FeedEventType;
    state: State;
    stateValue: Scalars['String'];
    timestamp: Scalars['String'];
};

export type StateValueFilter = {
    categories?: InputMaybe<Array<Scalars['String']>>;
    expired?: InputMaybe<Scalars['Boolean']>;
    stateIds?: InputMaybe<Array<Scalars['String']>>;
    types?: InputMaybe<Array<StateType>>;
    visibleAtCategory?: InputMaybe<Scalars['Boolean']>;
};

export type StateValueInput = {
    stateId: Scalars['String'];
    value: Scalars['String'];
};

export type StateValueWithId = {
    __typename?: 'StateValueWithId';
    stateId: Scalars['String'];
    value: Scalars['String'];
};

export type Subscription = {
    __typename?: 'Subscription';
    stateValueChanges: StateValue;
};

export type SubscriptionStateValueChangesArgs = {
    deliverPolicy?: InputMaybe<DeliverPolicy>;
    jwt: Scalars['String'];
    stateId?: InputMaybe<Scalars['String']>;
};

export type SuggestedProjectGroup = {
    __typename?: 'SuggestedProjectGroup';
    countOfProjects: Scalars['Int'];
    id: Scalars['ID'];
    name: Scalars['String'];
    projects: ProjectsPaginated;
    statesOfInterest?: Maybe<Array<Maybe<State>>>;
};

export type SuggestedProjectGroupsPaginated = {
    __typename?: 'SuggestedProjectGroupsPaginated';
    pageInfo: PageInfo;
    projectGroups: Array<SuggestedProjectGroup>;
};

export type TargetStateValue = {
    __typename?: 'TargetStateValue';
    stateId: Scalars['String'];
    value: Scalars['String'];
};

export type TargetStateValueInput = {
    stateId: Scalars['String'];
    value: Scalars['String'];
};

export type Task = CheckItemTask | LearningTask | StateCollectionTask;

export type TaskFilter = {
    id?: InputMaybe<Scalars['String']>;
    search?: InputMaybe<Scalars['String']>;
    taskType?: InputMaybe<TaskType>;
};

export type TaskInput = {
    checkItemTemplateInput?: InputMaybe<Array<CheckItemTemplateInput>>;
    description: Scalars['String'];
    id?: InputMaybe<Scalars['ID']>;
    name: Scalars['String'];
    note?: InputMaybe<Scalars['String']>;
    references?: InputMaybe<Array<ReferenceInput>>;
    taskStateValues: Array<TaskStateValueInput>;
    taskType: TaskType;
};

export type TaskStateValue = {
    __typename?: 'TaskStateValue';
    overwrittenDefaultQuestion?: Maybe<Scalars['String']>;
    state?: Maybe<State>;
    stateId?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type TaskStateValueInput = {
    overwrittenDefaultQuestion?: InputMaybe<Scalars['String']>;
    stateId: Scalars['String'];
    value?: InputMaybe<Scalars['String']>;
};

export type TaskType = 'CheckItemTask' | 'LearningTask' | 'StateCollectionTask';

export type TasksPage = {
    __typename?: 'TasksPage';
    entities: Array<Task>;
    pageInfo: PageInfo;
};

export type Unit = {
    __typename?: 'Unit';
    displaynameDE?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    name: Scalars['String'];
};

export type UnitInput = {
    displaynameDE?: InputMaybe<Scalars['String']>;
    id: Scalars['String'];
    name: Scalars['String'];
};

export type UserCheckItem = {
    __typename?: 'UserCheckItem';
    checked: Scalars['Boolean'];
    createdAt?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    mandatory?: Maybe<Scalars['Boolean']>;
    order?: Maybe<Scalars['Int']>;
    title: Scalars['String'];
    updatedAt?: Maybe<Scalars['String']>;
};

export type UserCheckItemInput = {
    checkItemId: Scalars['String'];
    checked: Scalars['Boolean'];
};

export type UserProject = {
    __typename?: 'UserProject';
    id: Scalars['ID'];
    latestStateChange: Scalars['String'];
    name: Scalars['String'];
    projectId: Scalars['ID'];
    projectObjective: Scalars['String'];
    startTime: Scalars['String'];
    targetStateValues?: Maybe<Array<TargetStateValue>>;
    userId: Scalars['String'];
    userProjectState: UserProjectState;
    userTasks?: Maybe<Array<Maybe<UserTask>>>;
};

export type UserProjectColumnName =
    | 'id'
    | 'latestStateChange'
    | 'name'
    | 'projectId'
    | 'projectObjective'
    | 'startTime'
    | 'targetStateValues'
    | 'userId';

export type UserProjectOrderByEntry = {
    key: UserProjectColumnName;
    order: SortOrder;
};

export type UserProjectResult = {
    __typename?: 'UserProjectResult';
    entities: Array<UserProject>;
    pageInfo: PageInfo;
};

export type UserProjectSorting = {
    sorting?: InputMaybe<Array<UserProjectOrderByEntry>>;
};

export type UserProjectState = 'ACTIVE' | 'CANCELLED' | 'DECLINED' | 'FINISHED';

export type UserProjectStateFilter = 'ACTIVE' | 'FINISHED';

export type UserSuggestion = {
    __typename?: 'UserSuggestion';
    newProjects: ProjectsPaginated;
    popularProjects: ProjectsPaginated;
    projectGroupMatches: SuggestedProjectGroupsPaginated;
    recommendedProjects: ProjectsPaginated;
};

export type UserSuggestionInput = {
    newProjects?: InputMaybe<PaginationParams>;
    popularProjects?: InputMaybe<PaginationParams>;
    projectGroupMatches?: InputMaybe<PaginationParams>;
    projectGroupProjectsPaginations?: InputMaybe<Array<ProjectGroupProjectsPagination>>;
    recommendedProjects?: InputMaybe<PaginationParams>;
};

export type UserTask = {
    __typename?: 'UserTask';
    checkItems?: Maybe<Array<Maybe<UserCheckItem>>>;
    createdAt: Scalars['String'];
    description: Scalars['String'];
    id: Scalars['ID'];
    latestStateChange?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    references?: Maybe<Array<Reference>>;
    sortKey?: Maybe<Scalars['Int']>;
    taskId: Scalars['ID'];
    taskStateValues?: Maybe<Array<TaskStateValue>>;
    taskType: TaskType;
    updatedAt: Scalars['String'];
    userId: Scalars['String'];
    userTaskResults?: Maybe<Array<UserTaskResult>>;
    userTaskStatus: UserTaskStatus;
};

export type UserTaskResult = {
    __typename?: 'UserTaskResult';
    seqNumber: Scalars['String'];
    stateId: Scalars['String'];
    value: Scalars['String'];
};

export type UserTaskResultInput = {
    stateId: Scalars['String'];
    value: Scalars['String'];
};

export type UserTaskStatus = 'ACTIVE' | 'CANCELLED' | 'FINISHED';

export type ValueInterval = {
    __typename?: 'ValueInterval';
    criteria?: Maybe<Array<Maybe<FilterCriteria>>>;
    end: Scalars['Float'];
    labels?: Maybe<Array<Scalars['String']>>;
    start: Scalars['Float'];
    widthPercentage?: Maybe<Scalars['Float']>;
};

export type ValueIntervalInput = {
    criteria?: InputMaybe<Array<InputMaybe<FilterCriteriaInput>>>;
    end: Scalars['Float'];
    labels?: InputMaybe<Array<Scalars['String']>>;
    start: Scalars['Float'];
    widthPercentage?: InputMaybe<Scalars['Float']>;
};

export type ValueRange = {
    __typename?: 'ValueRange';
    intervals?: Maybe<Array<Maybe<ValueInterval>>>;
    max: Scalars['Float'];
    min: Scalars['Float'];
};

export type ValueRangeInput = {
    intervals?: InputMaybe<Array<ValueIntervalInput>>;
    max: Scalars['Float'];
    min: Scalars['Float'];
};

export type XapiCourseStructure = {
    __typename?: 'XapiCourseStructure';
    activityId?: Maybe<Scalars['String']>;
    blocks?: Maybe<Array<XapiCourseStructureBlock>>;
    description?: Maybe<Scalars['String']>;
    launchPath?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type XapiCourseStructureBlock = {
    __typename?: 'XapiCourseStructureBlock';
    activityId: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    ordinal?: Maybe<Scalars['Int']>;
    title?: Maybe<Scalars['String']>;
};

export type XapiCourseUploadInfo = {
    __typename?: 'XapiCourseUploadInfo';
    uploadDate: Scalars['String'];
    zipFileName: Scalars['String'];
};

export type FilterCriteria_GetAllStatesQueryVariables = Exact<{ [key: string]: never }>;

export type FilterCriteria_GetAllStatesQuery = {
    __typename?: 'Query';
    states: Array<
        | {
              __typename: 'StateDate';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              defaultQuestion: string;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
          }
        | {
              __typename: 'StateNumeric';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              defaultQuestion: string;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
              valueRange?: {
                  __typename?: 'ValueRange';
                  min: number;
                  max: number;
                  intervals?: Array<{
                      __typename?: 'ValueInterval';
                      start: number;
                      end: number;
                      labels?: Array<string> | null;
                  } | null> | null;
              } | null;
          }
        | {
              __typename: 'StateSelection';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              defaultQuestion: string;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
          }
        | {
              __typename: 'StateString';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              defaultQuestion: string;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
          }
    >;
};

export type ProjectGroupsSelect_GroupsQueryVariables = Exact<{ [key: string]: never }>;

export type ProjectGroupsSelect_GroupsQuery = {
    __typename?: 'Query';
    projectGroups: Array<{ __typename?: 'ProjectGroup'; id: string; name: string }>;
};

type StateInputStateDependency_StateDate_Fragment = {
    __typename?: 'StateDate';
    id: string;
    name: string;
    type: StateType;
    dataType: DataType;
    visibleAtCategory: boolean;
    visibleAtDiary?: boolean | null;
    defaultQuestion: string;
    categories: Array<{ __typename?: 'Category'; name: string }>;
    stateDependencies?: Array<
        | {
              __typename?: 'StateDate';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              defaultQuestion: string;
              categories: Array<{ __typename?: 'Category'; name: string }>;
          }
        | {
              __typename?: 'StateNumeric';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              defaultQuestion: string;
              categories: Array<{ __typename?: 'Category'; name: string }>;
              unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
              valueRange?: {
                  __typename?: 'ValueRange';
                  min: number;
                  max: number;
                  intervals?: Array<{
                      __typename?: 'ValueInterval';
                      start: number;
                      end: number;
                      labels?: Array<string> | null;
                  } | null> | null;
              } | null;
          }
        | {
              __typename?: 'StateSelection';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              defaultQuestion: string;
              categories: Array<{ __typename?: 'Category'; name: string }>;
              selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
          }
        | {
              __typename?: 'StateString';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              defaultQuestion: string;
              categories: Array<{ __typename?: 'Category'; name: string }>;
          }
    > | null;
};

type StateInputStateDependency_StateNumeric_Fragment = {
    __typename?: 'StateNumeric';
    id: string;
    name: string;
    type: StateType;
    dataType: DataType;
    visibleAtCategory: boolean;
    visibleAtDiary?: boolean | null;
    defaultQuestion: string;
    categories: Array<{ __typename?: 'Category'; name: string }>;
    stateDependencies?: Array<
        | {
              __typename?: 'StateDate';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              defaultQuestion: string;
              categories: Array<{ __typename?: 'Category'; name: string }>;
          }
        | {
              __typename?: 'StateNumeric';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              defaultQuestion: string;
              categories: Array<{ __typename?: 'Category'; name: string }>;
              unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
              valueRange?: {
                  __typename?: 'ValueRange';
                  min: number;
                  max: number;
                  intervals?: Array<{
                      __typename?: 'ValueInterval';
                      start: number;
                      end: number;
                      labels?: Array<string> | null;
                  } | null> | null;
              } | null;
          }
        | {
              __typename?: 'StateSelection';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              defaultQuestion: string;
              categories: Array<{ __typename?: 'Category'; name: string }>;
              selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
          }
        | {
              __typename?: 'StateString';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              defaultQuestion: string;
              categories: Array<{ __typename?: 'Category'; name: string }>;
          }
    > | null;
    unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
    valueRange?: {
        __typename?: 'ValueRange';
        min: number;
        max: number;
        intervals?: Array<{
            __typename?: 'ValueInterval';
            start: number;
            end: number;
            labels?: Array<string> | null;
        } | null> | null;
    } | null;
};

type StateInputStateDependency_StateSelection_Fragment = {
    __typename?: 'StateSelection';
    id: string;
    name: string;
    type: StateType;
    dataType: DataType;
    visibleAtCategory: boolean;
    visibleAtDiary?: boolean | null;
    defaultQuestion: string;
    categories: Array<{ __typename?: 'Category'; name: string }>;
    stateDependencies?: Array<
        | {
              __typename?: 'StateDate';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              defaultQuestion: string;
              categories: Array<{ __typename?: 'Category'; name: string }>;
          }
        | {
              __typename?: 'StateNumeric';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              defaultQuestion: string;
              categories: Array<{ __typename?: 'Category'; name: string }>;
              unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
              valueRange?: {
                  __typename?: 'ValueRange';
                  min: number;
                  max: number;
                  intervals?: Array<{
                      __typename?: 'ValueInterval';
                      start: number;
                      end: number;
                      labels?: Array<string> | null;
                  } | null> | null;
              } | null;
          }
        | {
              __typename?: 'StateSelection';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              defaultQuestion: string;
              categories: Array<{ __typename?: 'Category'; name: string }>;
              selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
          }
        | {
              __typename?: 'StateString';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              defaultQuestion: string;
              categories: Array<{ __typename?: 'Category'; name: string }>;
          }
    > | null;
    selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
};

type StateInputStateDependency_StateString_Fragment = {
    __typename?: 'StateString';
    id: string;
    name: string;
    type: StateType;
    dataType: DataType;
    visibleAtCategory: boolean;
    visibleAtDiary?: boolean | null;
    defaultQuestion: string;
    categories: Array<{ __typename?: 'Category'; name: string }>;
    stateDependencies?: Array<
        | {
              __typename?: 'StateDate';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              defaultQuestion: string;
              categories: Array<{ __typename?: 'Category'; name: string }>;
          }
        | {
              __typename?: 'StateNumeric';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              defaultQuestion: string;
              categories: Array<{ __typename?: 'Category'; name: string }>;
              unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
              valueRange?: {
                  __typename?: 'ValueRange';
                  min: number;
                  max: number;
                  intervals?: Array<{
                      __typename?: 'ValueInterval';
                      start: number;
                      end: number;
                      labels?: Array<string> | null;
                  } | null> | null;
              } | null;
          }
        | {
              __typename?: 'StateSelection';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              defaultQuestion: string;
              categories: Array<{ __typename?: 'Category'; name: string }>;
              selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
          }
        | {
              __typename?: 'StateString';
              id: string;
              name: string;
              type: StateType;
              dataType: DataType;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              defaultQuestion: string;
              categories: Array<{ __typename?: 'Category'; name: string }>;
          }
    > | null;
};

export type StateInputStateDependencyFragment =
    | StateInputStateDependency_StateDate_Fragment
    | StateInputStateDependency_StateNumeric_Fragment
    | StateInputStateDependency_StateSelection_Fragment
    | StateInputStateDependency_StateString_Fragment;

export type State_Input_StatesQueryVariables = Exact<{
    search?: InputMaybe<Scalars['String']>;
}>;

export type State_Input_StatesQuery = {
    __typename?: 'Query';
    states: Array<
        | {
              __typename?: 'StateDate';
              id: string;
              name: string;
              dataType: DataType;
              type: StateType;
              defaultQuestion: string;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              references?: Array<{
                  __typename: 'Reference';
                  description?: string | null;
                  referenceType: ReferenceType;
              }> | null;
              stateDependencies?: Array<
                  | {
                        __typename?: 'StateDate';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                    }
                  | {
                        __typename?: 'StateNumeric';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                        unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
                        valueRange?: {
                            __typename?: 'ValueRange';
                            min: number;
                            max: number;
                            intervals?: Array<{
                                __typename?: 'ValueInterval';
                                start: number;
                                end: number;
                                labels?: Array<string> | null;
                            } | null> | null;
                        } | null;
                    }
                  | {
                        __typename?: 'StateSelection';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                        selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
                    }
                  | {
                        __typename?: 'StateString';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                    }
              > | null;
          }
        | {
              __typename?: 'StateNumeric';
              id: string;
              name: string;
              dataType: DataType;
              type: StateType;
              defaultQuestion: string;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              references?: Array<{
                  __typename: 'Reference';
                  description?: string | null;
                  referenceType: ReferenceType;
              }> | null;
              stateDependencies?: Array<
                  | {
                        __typename?: 'StateDate';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                    }
                  | {
                        __typename?: 'StateNumeric';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                        unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
                        valueRange?: {
                            __typename?: 'ValueRange';
                            min: number;
                            max: number;
                            intervals?: Array<{
                                __typename?: 'ValueInterval';
                                start: number;
                                end: number;
                                labels?: Array<string> | null;
                            } | null> | null;
                        } | null;
                    }
                  | {
                        __typename?: 'StateSelection';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                        selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
                    }
                  | {
                        __typename?: 'StateString';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                    }
              > | null;
              unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
              valueRange?: {
                  __typename?: 'ValueRange';
                  min: number;
                  max: number;
                  intervals?: Array<{
                      __typename?: 'ValueInterval';
                      start: number;
                      end: number;
                      labels?: Array<string> | null;
                  } | null> | null;
              } | null;
          }
        | {
              __typename?: 'StateSelection';
              id: string;
              name: string;
              dataType: DataType;
              type: StateType;
              defaultQuestion: string;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              references?: Array<{
                  __typename: 'Reference';
                  description?: string | null;
                  referenceType: ReferenceType;
              }> | null;
              stateDependencies?: Array<
                  | {
                        __typename?: 'StateDate';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                    }
                  | {
                        __typename?: 'StateNumeric';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                        unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
                        valueRange?: {
                            __typename?: 'ValueRange';
                            min: number;
                            max: number;
                            intervals?: Array<{
                                __typename?: 'ValueInterval';
                                start: number;
                                end: number;
                                labels?: Array<string> | null;
                            } | null> | null;
                        } | null;
                    }
                  | {
                        __typename?: 'StateSelection';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                        selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
                    }
                  | {
                        __typename?: 'StateString';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                    }
              > | null;
              selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
          }
        | {
              __typename?: 'StateString';
              id: string;
              name: string;
              dataType: DataType;
              type: StateType;
              defaultQuestion: string;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              references?: Array<{
                  __typename: 'Reference';
                  description?: string | null;
                  referenceType: ReferenceType;
              }> | null;
              stateDependencies?: Array<
                  | {
                        __typename?: 'StateDate';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                    }
                  | {
                        __typename?: 'StateNumeric';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                        unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
                        valueRange?: {
                            __typename?: 'ValueRange';
                            min: number;
                            max: number;
                            intervals?: Array<{
                                __typename?: 'ValueInterval';
                                start: number;
                                end: number;
                                labels?: Array<string> | null;
                            } | null> | null;
                        } | null;
                    }
                  | {
                        __typename?: 'StateSelection';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                        selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
                    }
                  | {
                        __typename?: 'StateString';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                    }
              > | null;
          }
    >;
};

export type TaskMutate_GetAllStatesFilteredQueryVariables = Exact<{
    filter?: InputMaybe<StateFilter>;
}>;

export type TaskMutate_GetAllStatesFilteredQuery = {
    __typename?: 'Query';
    states: Array<
        | {
              __typename?: 'StateDate';
              id: string;
              name: string;
              dataType: DataType;
              type: StateType;
              defaultQuestion: string;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              references?: Array<{
                  __typename: 'Reference';
                  description?: string | null;
                  referenceType: ReferenceType;
              }> | null;
              stateDependencies?: Array<
                  | {
                        __typename?: 'StateDate';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                    }
                  | {
                        __typename?: 'StateNumeric';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                        unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
                        valueRange?: {
                            __typename?: 'ValueRange';
                            min: number;
                            max: number;
                            intervals?: Array<{
                                __typename?: 'ValueInterval';
                                start: number;
                                end: number;
                                labels?: Array<string> | null;
                            } | null> | null;
                        } | null;
                    }
                  | {
                        __typename?: 'StateSelection';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                        selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
                    }
                  | {
                        __typename?: 'StateString';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                    }
              > | null;
          }
        | {
              __typename?: 'StateNumeric';
              id: string;
              name: string;
              dataType: DataType;
              type: StateType;
              defaultQuestion: string;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              references?: Array<{
                  __typename: 'Reference';
                  description?: string | null;
                  referenceType: ReferenceType;
              }> | null;
              stateDependencies?: Array<
                  | {
                        __typename?: 'StateDate';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                    }
                  | {
                        __typename?: 'StateNumeric';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                        unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
                        valueRange?: {
                            __typename?: 'ValueRange';
                            min: number;
                            max: number;
                            intervals?: Array<{
                                __typename?: 'ValueInterval';
                                start: number;
                                end: number;
                                labels?: Array<string> | null;
                            } | null> | null;
                        } | null;
                    }
                  | {
                        __typename?: 'StateSelection';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                        selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
                    }
                  | {
                        __typename?: 'StateString';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                    }
              > | null;
              unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
              valueRange?: {
                  __typename?: 'ValueRange';
                  min: number;
                  max: number;
                  intervals?: Array<{
                      __typename?: 'ValueInterval';
                      start: number;
                      end: number;
                      labels?: Array<string> | null;
                  } | null> | null;
              } | null;
          }
        | {
              __typename?: 'StateSelection';
              id: string;
              name: string;
              dataType: DataType;
              type: StateType;
              defaultQuestion: string;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              references?: Array<{
                  __typename: 'Reference';
                  description?: string | null;
                  referenceType: ReferenceType;
              }> | null;
              stateDependencies?: Array<
                  | {
                        __typename?: 'StateDate';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                    }
                  | {
                        __typename?: 'StateNumeric';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                        unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
                        valueRange?: {
                            __typename?: 'ValueRange';
                            min: number;
                            max: number;
                            intervals?: Array<{
                                __typename?: 'ValueInterval';
                                start: number;
                                end: number;
                                labels?: Array<string> | null;
                            } | null> | null;
                        } | null;
                    }
                  | {
                        __typename?: 'StateSelection';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                        selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
                    }
                  | {
                        __typename?: 'StateString';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                    }
              > | null;
              selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
          }
        | {
              __typename?: 'StateString';
              id: string;
              name: string;
              dataType: DataType;
              type: StateType;
              defaultQuestion: string;
              visibleAtCategory: boolean;
              visibleAtDiary?: boolean | null;
              references?: Array<{
                  __typename: 'Reference';
                  description?: string | null;
                  referenceType: ReferenceType;
              }> | null;
              stateDependencies?: Array<
                  | {
                        __typename?: 'StateDate';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                    }
                  | {
                        __typename?: 'StateNumeric';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                        unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
                        valueRange?: {
                            __typename?: 'ValueRange';
                            min: number;
                            max: number;
                            intervals?: Array<{
                                __typename?: 'ValueInterval';
                                start: number;
                                end: number;
                                labels?: Array<string> | null;
                            } | null> | null;
                        } | null;
                    }
                  | {
                        __typename?: 'StateSelection';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                        selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
                    }
                  | {
                        __typename?: 'StateString';
                        id: string;
                        name: string;
                        type: StateType;
                        dataType: DataType;
                        visibleAtCategory: boolean;
                        visibleAtDiary?: boolean | null;
                        defaultQuestion: string;
                        categories: Array<{ __typename?: 'Category'; name: string }>;
                        stateDependencies?: Array<
                            | {
                                  __typename?: 'StateDate';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                            | {
                                  __typename?: 'StateNumeric';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  unit?: {
                                      __typename?: 'Unit';
                                      id: string;
                                      name: string;
                                      displaynameDE?: string | null;
                                  } | null;
                                  valueRange?: {
                                      __typename?: 'ValueRange';
                                      min: number;
                                      max: number;
                                      intervals?: Array<{
                                          __typename?: 'ValueInterval';
                                          start: number;
                                          end: number;
                                          labels?: Array<string> | null;
                                      } | null> | null;
                                  } | null;
                              }
                            | {
                                  __typename?: 'StateSelection';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                                  selectionOptions: Array<{
                                      __typename?: 'StateSelectionOption';
                                      id: string;
                                      name: string;
                                  }>;
                              }
                            | {
                                  __typename?: 'StateString';
                                  id: string;
                                  name: string;
                                  type: StateType;
                                  dataType: DataType;
                                  visibleAtCategory: boolean;
                                  visibleAtDiary?: boolean | null;
                                  defaultQuestion: string;
                                  categories: Array<{ __typename?: 'Category'; name: string }>;
                              }
                        > | null;
                    }
              > | null;
          }
    >;
};

export type TaskMutate_GetLearningTaskUploadUrlMutationVariables = Exact<{ [key: string]: never }>;

export type TaskMutate_GetLearningTaskUploadUrlMutation = { __typename?: 'Mutation'; learningTaskUploadUrl: string };

export type TaskMutate_CreateTaskMutationVariables = Exact<{
    input: TaskInput;
}>;

export type TaskMutate_CreateTaskMutation = {
    __typename?: 'Mutation';
    createTask:
        | { __typename?: 'CheckItemTask'; id: string; name: string; note?: string | null }
        | { __typename?: 'LearningTask'; id: string; name: string; note?: string | null }
        | { __typename?: 'StateCollectionTask'; id: string; name: string; note?: string | null };
};

export type TaskMutate_TaskMutationVariables = Exact<{
    input: TaskInput;
}>;

export type TaskMutate_TaskMutation = {
    __typename?: 'Mutation';
    task:
        | { __typename?: 'CheckItemTask'; id: string; name: string; note?: string | null }
        | { __typename?: 'LearningTask'; id: string; name: string; note?: string | null }
        | { __typename?: 'StateCollectionTask'; id: string; name: string; note?: string | null };
};

export type TaskMutate_MapFileToLearningTaskMutationVariables = Exact<{
    zipFileName: Scalars['String'];
    taskId: Scalars['ID'];
}>;

export type TaskMutate_MapFileToLearningTaskMutation = {
    __typename?: 'Mutation';
    mapUploadedXapiCourseZipToLearningTask:
        | { __typename?: 'CheckItemTask'; id: string; name: string }
        | { __typename?: 'LearningTask'; id: string; name: string }
        | { __typename?: 'StateCollectionTask'; id: string; name: string };
};

export type Task_Select_GetTaskByFilterQueryVariables = Exact<{
    filter?: InputMaybe<Scalars['String']>;
}>;

export type Task_Select_GetTaskByFilterQuery = {
    __typename?: 'Query';
    tasks: Array<
        | {
              __typename?: 'CheckItemTask';
              id: string;
              name: string;
              description: string;
              taskType: TaskType;
              note?: string | null;
          }
        | {
              __typename?: 'LearningTask';
              id: string;
              name: string;
              description: string;
              taskType: TaskType;
              note?: string | null;
          }
        | {
              __typename?: 'StateCollectionTask';
              id: string;
              name: string;
              description: string;
              taskType: TaskType;
              note?: string | null;
          }
    >;
};

export type Project_Create_CreateProjectMutationVariables = Exact<{
    input: ProjectTemplateInput;
}>;

export type Project_Create_CreateProjectMutation = {
    __typename?: 'Mutation';
    projectTemplate: { __typename?: 'ProjectTemplate'; id: string };
};

export type Project_Create_GetCurrentProjectQueryVariables = Exact<{
    id?: InputMaybe<Scalars['String']>;
}>;

export type Project_Create_GetCurrentProjectQuery = {
    __typename?: 'Query';
    projectTemplateById: {
        __typename?: 'ProjectTemplate';
        id: string;
        name: string;
        note?: string | null;
        durationMinutes?: number | null;
        projectObjective: string;
        status?: ProjectTemplateStatus | null;
        targetStateValues: Array<{ __typename?: 'TargetStateValue'; stateId: string; value: string }>;
        filterCriteria?: Array<
            Array<{ __typename?: 'FilterCriteria'; stateId: string; operator: Operator; value: string }>
        > | null;
        recommendationCriteria?: Array<
            Array<{ __typename?: 'FilterCriteria'; stateId: string; operator: Operator; value: string }>
        > | null;
        projectGroups: Array<{ __typename?: 'ProjectGroup'; id: string; name: string }>;
        tasks: Array<
            | { __typename: 'CheckItemTask'; id: string; name: string; note?: string | null }
            | { __typename: 'LearningTask'; id: string; name: string; note?: string | null }
            | { __typename: 'StateCollectionTask'; id: string; name: string; note?: string | null }
        >;
    };
};

export type Project_Create_ProjectStatusMutationVariables = Exact<{
    id: Scalars['ID'];
    status: ProjectTemplateStatus;
}>;

export type Project_Create_ProjectStatusMutation = { __typename?: 'Mutation'; projectTemplateStatus: boolean };

export type Project_Create_Input_StatesQueryVariables = Exact<{
    id?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type Project_Create_Input_StatesQuery = {
    __typename?: 'Query';
    states: Array<
        | { __typename?: 'StateDate'; name: string }
        | { __typename?: 'StateNumeric'; name: string; unit?: { __typename?: 'Unit'; id: string } | null }
        | {
              __typename?: 'StateSelection';
              name: string;
              selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
          }
        | { __typename?: 'StateString'; name: string }
    >;
};

export type ProjectEditor_GetAllProjectQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
    count?: InputMaybe<Scalars['Int']>;
    filter?: InputMaybe<ProjectTemplatesFilter>;
}>;

export type ProjectEditor_GetAllProjectQuery = {
    __typename?: 'Query';
    projectTemplatesPage: {
        __typename?: 'ProjectTemplatePage';
        entities: Array<{
            __typename?: 'ProjectTemplate';
            id: string;
            name: string;
            projectObjective: string;
            status?: ProjectTemplateStatus | null;
            note?: string | null;
            projectGroups: Array<{ __typename?: 'ProjectGroup'; name: string }>;
        }>;
        pageInfo: { __typename?: 'PageInfo'; pages: number; page: number; count: number; totalCount: number };
    };
};

export type StateExportQueryVariables = Exact<{ [key: string]: never }>;

export type StateExportQuery = {
    __typename?: 'Query';
    stateExport: Array<{
        __typename?: 'StateExport';
        id?: string | null;
        name?: string | null;
        categories?: Array<string | null> | null;
        dataType?: DataType | null;
        type?: StateType | null;
        visibleAtCategory?: boolean | null;
        defaultQuestion?: string | null;
        lifeTimeMs?: number | null;
        calculationType?: CalculationType | null;
        unit?: string | null;
        visibleAtDiary?: boolean | null;
        description?: Array<{ __typename?: 'DescriptionBlock'; title: string; description: string } | null> | null;
        references?: Array<{
            __typename?: 'Reference';
            referenceType: ReferenceType;
            description?: string | null;
        } | null> | null;
        stateDependencies?: Array<{ __typename?: 'CalculationStateParameter'; stateId: string } | null> | null;
        effectTypeText?: {
            __typename?: 'EffectTypeText';
            positive?: string | null;
            negative?: string | null;
            neutral?: string | null;
        } | null;
        valueRange?: {
            __typename?: 'ValueRange';
            min: number;
            max: number;
            intervals?: Array<{
                __typename?: 'ValueInterval';
                start: number;
                end: number;
                labels?: Array<string> | null;
                widthPercentage?: number | null;
                criteria?: Array<{
                    __typename?: 'FilterCriteria';
                    stateId: string;
                    operator: Operator;
                    value: string;
                } | null> | null;
            } | null> | null;
        } | null;
        selectionOptions?: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string } | null> | null;
    } | null>;
};

export type AllStatesCountQueryVariables = Exact<{
    filter?: InputMaybe<StateFilter>;
    page?: InputMaybe<Scalars['Int']>;
    count?: InputMaybe<Scalars['Int']>;
}>;

export type AllStatesCountQuery = {
    __typename?: 'Query';
    statePage: { __typename?: 'StateResult'; pageInfo: { __typename?: 'PageInfo'; totalCount: number } };
};

export type ImportStatesMutationVariables = Exact<{
    input: Array<StateExportInput> | StateExportInput;
}>;

export type ImportStatesMutation = { __typename?: 'Mutation'; importStates: boolean };

export type MutateState_GetAllStatesIdsQueryVariables = Exact<{ [key: string]: never }>;

export type MutateState_GetAllStatesIdsQuery = {
    __typename?: 'Query';
    states: Array<
        | { __typename: 'StateDate'; id: string }
        | { __typename: 'StateNumeric'; id: string }
        | { __typename: 'StateSelection'; id: string }
        | { __typename: 'StateString'; id: string }
    >;
};

export type StateForm_UpdateStateMutationVariables = Exact<{
    input: StateUpdateInput;
}>;

export type StateForm_UpdateStateMutation = {
    __typename?: 'Mutation';
    updateState:
        | { __typename?: 'StateDate'; id: string }
        | { __typename?: 'StateNumeric'; id: string }
        | { __typename?: 'StateSelection'; id: string }
        | { __typename?: 'StateString'; id: string };
};

export type GetAllStatesQueryVariables = Exact<{
    filter?: InputMaybe<StateFilter>;
    page?: InputMaybe<Scalars['Int']>;
    count?: InputMaybe<Scalars['Int']>;
}>;

export type GetAllStatesQuery = {
    __typename?: 'Query';
    statePage: {
        __typename?: 'StateResult';
        pageInfo: { __typename?: 'PageInfo'; pages: number; page: number; count: number; totalCount: number };
        entities: Array<
            | {
                  __typename?: 'StateDate';
                  id: string;
                  name: string;
                  type: StateType;
                  dataType: DataType;
                  defaultQuestion: string;
                  visibleAtCategory: boolean;
                  visibleAtDiary?: boolean | null;
                  lifeTimeMs?: any | null;
                  categories: Array<{ __typename?: 'Category'; name: string; description: string }>;
                  description: Array<{ __typename?: 'DescriptionBlock'; title: string; description: string }>;
                  references?: Array<{
                      __typename?: 'Reference';
                      description?: string | null;
                      referenceType: ReferenceType;
                  }> | null;
                  effectTypeText?: {
                      __typename?: 'EffectTypeText';
                      positive?: string | null;
                      neutral?: string | null;
                      negative?: string | null;
                  } | null;
              }
            | {
                  __typename?: 'StateNumeric';
                  id: string;
                  name: string;
                  type: StateType;
                  dataType: DataType;
                  defaultQuestion: string;
                  visibleAtCategory: boolean;
                  visibleAtDiary?: boolean | null;
                  lifeTimeMs?: any | null;
                  categories: Array<{ __typename?: 'Category'; name: string; description: string }>;
                  description: Array<{ __typename?: 'DescriptionBlock'; title: string; description: string }>;
                  references?: Array<{
                      __typename?: 'Reference';
                      description?: string | null;
                      referenceType: ReferenceType;
                  }> | null;
                  effectTypeText?: {
                      __typename?: 'EffectTypeText';
                      positive?: string | null;
                      neutral?: string | null;
                      negative?: string | null;
                  } | null;
                  unit?: { __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null } | null;
                  valueRange?: {
                      __typename?: 'ValueRange';
                      min: number;
                      max: number;
                      intervals?: Array<{
                          __typename?: 'ValueInterval';
                          start: number;
                          end: number;
                          labels?: Array<string> | null;
                          widthPercentage?: number | null;
                          criteria?: Array<{
                              __typename?: 'FilterCriteria';
                              stateId: string;
                              operator: Operator;
                              value: string;
                          } | null> | null;
                      } | null> | null;
                  } | null;
              }
            | {
                  __typename?: 'StateSelection';
                  id: string;
                  name: string;
                  type: StateType;
                  dataType: DataType;
                  defaultQuestion: string;
                  visibleAtCategory: boolean;
                  visibleAtDiary?: boolean | null;
                  lifeTimeMs?: any | null;
                  categories: Array<{ __typename?: 'Category'; name: string; description: string }>;
                  description: Array<{ __typename?: 'DescriptionBlock'; title: string; description: string }>;
                  references?: Array<{
                      __typename?: 'Reference';
                      description?: string | null;
                      referenceType: ReferenceType;
                  }> | null;
                  effectTypeText?: {
                      __typename?: 'EffectTypeText';
                      positive?: string | null;
                      neutral?: string | null;
                      negative?: string | null;
                  } | null;
                  selectionOptions: Array<{ __typename?: 'StateSelectionOption'; id: string; name: string }>;
              }
            | {
                  __typename?: 'StateString';
                  id: string;
                  name: string;
                  type: StateType;
                  dataType: DataType;
                  defaultQuestion: string;
                  visibleAtCategory: boolean;
                  visibleAtDiary?: boolean | null;
                  lifeTimeMs?: any | null;
                  categories: Array<{ __typename?: 'Category'; name: string; description: string }>;
                  description: Array<{ __typename?: 'DescriptionBlock'; title: string; description: string }>;
                  references?: Array<{
                      __typename?: 'Reference';
                      description?: string | null;
                      referenceType: ReferenceType;
                  }> | null;
                  effectTypeText?: {
                      __typename?: 'EffectTypeText';
                      positive?: string | null;
                      neutral?: string | null;
                      negative?: string | null;
                  } | null;
              }
        >;
    };
};

export type CreateStateMutationVariables = Exact<{
    input: StateInput;
}>;

export type CreateStateMutation = {
    __typename?: 'Mutation';
    addState:
        | { __typename?: 'StateDate'; id: string }
        | { __typename?: 'StateNumeric'; id: string }
        | { __typename?: 'StateSelection'; id: string }
        | { __typename?: 'StateString'; id: string };
};

export type GetAllUnitsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUnitsQuery = {
    __typename?: 'Query';
    units: Array<{ __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null }>;
};

export type SearchUnitQueryVariables = Exact<{
    input: Scalars['String'];
}>;

export type SearchUnitQuery = {
    __typename?: 'Query';
    units: Array<{ __typename?: 'Unit'; id: string; name: string; displaynameDE?: string | null }>;
};

export type GetAllCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCategoriesQuery = {
    __typename?: 'Query';
    categories: Array<{ __typename?: 'Category'; name: string; area?: Array<string> | null }>;
};

export type StateCalculationUsagesQueryVariables = Exact<{
    stateId: Scalars['String'];
}>;

export type StateCalculationUsagesQuery = {
    __typename?: 'Query';
    stateCalculationUsages: Array<
        | { __typename?: 'StateDate'; id: string; name: string }
        | { __typename?: 'StateNumeric'; id: string; name: string }
        | { __typename?: 'StateSelection'; id: string; name: string }
        | { __typename?: 'StateString'; id: string; name: string }
        | null
    >;
};

export type Tasks_GetAllTaskQueryVariables = Exact<{ [key: string]: never }>;

export type Tasks_GetAllTaskQuery = {
    __typename?: 'Query';
    tasks: Array<
        | { __typename?: 'CheckItemTask'; id: string; name: string; taskType: TaskType }
        | { __typename?: 'LearningTask'; id: string; name: string; taskType: TaskType }
        | { __typename?: 'StateCollectionTask'; id: string; name: string; taskType: TaskType }
    >;
};

export type Tasks_GetAllTaskPageQueryVariables = Exact<{
    filter?: InputMaybe<TaskFilter>;
    page?: InputMaybe<Scalars['Int']>;
    count?: InputMaybe<Scalars['Int']>;
}>;

export type Tasks_GetAllTaskPageQuery = {
    __typename?: 'Query';
    taskPage: {
        __typename?: 'TasksPage';
        entities: Array<
            | {
                  __typename?: 'CheckItemTask';
                  id: string;
                  name: string;
                  note?: string | null;
                  description: string;
                  taskType: TaskType;
                  taskStateValues: Array<{
                      __typename?: 'TaskStateValue';
                      overwrittenDefaultQuestion?: string | null;
                      stateId?: string | null;
                      value?: string | null;
                  }>;
                  references?: Array<{
                      __typename: 'Reference';
                      description?: string | null;
                      referenceType: ReferenceType;
                  }> | null;
                  checkItemTemplates: Array<{
                      __typename?: 'CheckItemTemplate';
                      id: string;
                      title: string;
                      description?: string | null;
                      mandatory?: boolean | null;
                      checked?: boolean | null;
                      order?: number | null;
                  }>;
              }
            | {
                  __typename?: 'LearningTask';
                  id: string;
                  name: string;
                  note?: string | null;
                  description: string;
                  taskType: TaskType;
                  taskStateValues: Array<{
                      __typename?: 'TaskStateValue';
                      overwrittenDefaultQuestion?: string | null;
                      stateId?: string | null;
                      value?: string | null;
                  }>;
                  references?: Array<{
                      __typename: 'Reference';
                      description?: string | null;
                      referenceType: ReferenceType;
                  }> | null;
                  xapiCourseUploadInfo?: {
                      __typename: 'XapiCourseUploadInfo';
                      zipFileName: string;
                      uploadDate: string;
                  } | null;
              }
            | {
                  __typename?: 'StateCollectionTask';
                  id: string;
                  name: string;
                  note?: string | null;
                  description: string;
                  taskType: TaskType;
                  taskStateValues: Array<{
                      __typename?: 'TaskStateValue';
                      overwrittenDefaultQuestion?: string | null;
                      stateId?: string | null;
                      value?: string | null;
                  }>;
                  references?: Array<{
                      __typename: 'Reference';
                      description?: string | null;
                      referenceType: ReferenceType;
                  }> | null;
              }
        >;
        pageInfo: { __typename?: 'PageInfo'; pages: number; page: number; count: number; totalCount: number };
    };
};

export const StateInputStateDependencyFragmentDoc = gql`
    fragment StateInputStateDependency on StateI {
        ... on StateI {
            id
            name
            type
            dataType
            visibleAtCategory
            visibleAtDiary
            defaultQuestion
            categories {
                name
            }
            stateDependencies {
                ... on StateI {
                    id
                    name
                    type
                    dataType
                    visibleAtCategory
                    visibleAtDiary
                    defaultQuestion
                    categories {
                        name
                    }
                }
                ... on StateSelection {
                    selectionOptions {
                        id
                        name
                    }
                }
                ... on StateNumeric {
                    unit {
                        id
                        name
                        displaynameDE
                    }
                    valueRange {
                        min
                        max
                        intervals {
                            start
                            end
                            labels
                        }
                    }
                }
            }
        }
        ... on StateSelection {
            selectionOptions {
                id
                name
            }
        }
        ... on StateNumeric {
            unit {
                id
                name
                displaynameDE
            }
            valueRange {
                min
                max
                intervals {
                    start
                    end
                    labels
                }
            }
        }
    }
`;
export const FilterCriteria_GetAllStatesDocument = gql`
    query filterCriteria_GetAllStates {
        states {
            __typename
            ... on StateI {
                id
                name
                type
                dataType
                defaultQuestion
                visibleAtCategory
                visibleAtDiary
            }
            ... on StateNumeric {
                unit {
                    id
                    name
                    displaynameDE
                }
                valueRange {
                    min
                    max
                    intervals {
                        start
                        end
                        labels
                    }
                }
            }
            ... on StateSelection {
                selectionOptions {
                    id
                    name
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class FilterCriteria_GetAllStatesGQL extends Apollo.Query<
    FilterCriteria_GetAllStatesQuery,
    FilterCriteria_GetAllStatesQueryVariables
> {
    override document = FilterCriteria_GetAllStatesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProjectGroupsSelect_GroupsDocument = gql`
    query projectGroupsSelect_groups {
        projectGroups {
            id
            name
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class ProjectGroupsSelect_GroupsGQL extends Apollo.Query<
    ProjectGroupsSelect_GroupsQuery,
    ProjectGroupsSelect_GroupsQueryVariables
> {
    override document = ProjectGroupsSelect_GroupsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const State_Input_StatesDocument = gql`
    query state_input_states($search: String) {
        states(filter: { search: $search }) {
            ... on StateI {
                id
                name
                dataType
                type
                defaultQuestion
                visibleAtCategory
                visibleAtDiary
                references {
                    __typename
                    description
                    referenceType
                }
                stateDependencies {
                    ...StateInputStateDependency
                }
            }
            ... on StateSelection {
                selectionOptions {
                    id
                    name
                }
            }
            ... on StateNumeric {
                unit {
                    id
                    name
                    displaynameDE
                }
                valueRange {
                    min
                    max
                    intervals {
                        start
                        end
                        labels
                    }
                }
            }
        }
    }
    ${StateInputStateDependencyFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class State_Input_StatesGQL extends Apollo.Query<State_Input_StatesQuery, State_Input_StatesQueryVariables> {
    override document = State_Input_StatesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const TaskMutate_GetAllStatesFilteredDocument = gql`
    query taskMutate_getAllStatesFiltered($filter: StateFilter) {
        states(filter: $filter) {
            ... on StateI {
                id
                name
                dataType
                type
                defaultQuestion
                visibleAtCategory
                visibleAtDiary
                references {
                    __typename
                    description
                    referenceType
                }
                stateDependencies {
                    ... on StateI {
                        id
                        name
                        type
                        dataType
                        visibleAtCategory
                        visibleAtDiary
                        defaultQuestion
                        categories {
                            name
                        }
                        stateDependencies {
                            ... on StateI {
                                id
                                name
                                type
                                dataType
                                visibleAtCategory
                                visibleAtDiary
                                defaultQuestion
                                categories {
                                    name
                                }
                            }
                            ... on StateSelection {
                                selectionOptions {
                                    id
                                    name
                                }
                            }
                            ... on StateNumeric {
                                unit {
                                    id
                                    name
                                    displaynameDE
                                }
                                valueRange {
                                    min
                                    max
                                    intervals {
                                        start
                                        end
                                        labels
                                    }
                                }
                            }
                        }
                    }
                    ... on StateSelection {
                        selectionOptions {
                            id
                            name
                        }
                    }
                    ... on StateNumeric {
                        unit {
                            id
                            name
                            displaynameDE
                        }
                        valueRange {
                            min
                            max
                            intervals {
                                start
                                end
                                labels
                            }
                        }
                    }
                }
            }
            ... on StateSelection {
                selectionOptions {
                    id
                    name
                }
            }
            ... on StateNumeric {
                unit {
                    id
                    name
                    displaynameDE
                }
                valueRange {
                    min
                    max
                    intervals {
                        start
                        end
                        labels
                    }
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class TaskMutate_GetAllStatesFilteredGQL extends Apollo.Query<
    TaskMutate_GetAllStatesFilteredQuery,
    TaskMutate_GetAllStatesFilteredQueryVariables
> {
    override document = TaskMutate_GetAllStatesFilteredDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const TaskMutate_GetLearningTaskUploadUrlDocument = gql`
    mutation taskMutate_getLearningTaskUploadUrl {
        learningTaskUploadUrl
    }
`;

@Injectable({
    providedIn: 'root',
})
export class TaskMutate_GetLearningTaskUploadUrlGQL extends Apollo.Mutation<
    TaskMutate_GetLearningTaskUploadUrlMutation,
    TaskMutate_GetLearningTaskUploadUrlMutationVariables
> {
    override document = TaskMutate_GetLearningTaskUploadUrlDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const TaskMutate_CreateTaskDocument = gql`
    mutation taskMutate_createTask($input: TaskInput!) {
        createTask(input: $input) {
            ... on ITask {
                id
                name
                note
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class TaskMutate_CreateTaskGQL extends Apollo.Mutation<
    TaskMutate_CreateTaskMutation,
    TaskMutate_CreateTaskMutationVariables
> {
    override document = TaskMutate_CreateTaskDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const TaskMutate_TaskDocument = gql`
    mutation taskMutate_task($input: TaskInput!) {
        task(input: $input) {
            ... on ITask {
                id
                name
                note
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class TaskMutate_TaskGQL extends Apollo.Mutation<TaskMutate_TaskMutation, TaskMutate_TaskMutationVariables> {
    override document = TaskMutate_TaskDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const TaskMutate_MapFileToLearningTaskDocument = gql`
    mutation taskMutate_mapFileToLearningTask($zipFileName: String!, $taskId: ID!) {
        mapUploadedXapiCourseZipToLearningTask(zipFileName: $zipFileName, taskId: $taskId) {
            ... on ITask {
                id
                name
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class TaskMutate_MapFileToLearningTaskGQL extends Apollo.Mutation<
    TaskMutate_MapFileToLearningTaskMutation,
    TaskMutate_MapFileToLearningTaskMutationVariables
> {
    override document = TaskMutate_MapFileToLearningTaskDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const Task_Select_GetTaskByFilterDocument = gql`
    query task_select_getTaskByFilter($filter: String) {
        tasks(filter: { search: $filter }) {
            ... on ITask {
                id
                name
                description
                taskType
                note
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class Task_Select_GetTaskByFilterGQL extends Apollo.Query<
    Task_Select_GetTaskByFilterQuery,
    Task_Select_GetTaskByFilterQueryVariables
> {
    override document = Task_Select_GetTaskByFilterDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const Project_Create_CreateProjectDocument = gql`
    mutation project_create_createProject($input: ProjectTemplateInput!) {
        projectTemplate(input: $input) {
            id
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class Project_Create_CreateProjectGQL extends Apollo.Mutation<
    Project_Create_CreateProjectMutation,
    Project_Create_CreateProjectMutationVariables
> {
    override document = Project_Create_CreateProjectDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const Project_Create_GetCurrentProjectDocument = gql`
    query project_create_getCurrentProject($id: String) {
        projectTemplateById(id: $id) {
            id
            name
            note
            durationMinutes
            projectObjective
            status
            targetStateValues {
                stateId
                value
            }
            filterCriteria {
                stateId
                operator
                value
            }
            recommendationCriteria {
                stateId
                operator
                value
            }
            projectGroups {
                id
                name
            }
            tasks {
                ... on ITask {
                    __typename
                    id
                    name
                    note
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class Project_Create_GetCurrentProjectGQL extends Apollo.Query<
    Project_Create_GetCurrentProjectQuery,
    Project_Create_GetCurrentProjectQueryVariables
> {
    override document = Project_Create_GetCurrentProjectDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const Project_Create_ProjectStatusDocument = gql`
    mutation project_create_projectStatus($id: ID!, $status: ProjectTemplateStatus!) {
        projectTemplateStatus(id: $id, status: $status)
    }
`;

@Injectable({
    providedIn: 'root',
})
export class Project_Create_ProjectStatusGQL extends Apollo.Mutation<
    Project_Create_ProjectStatusMutation,
    Project_Create_ProjectStatusMutationVariables
> {
    override document = Project_Create_ProjectStatusDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const Project_Create_Input_StatesDocument = gql`
    query project_create_input_states($id: [String!]) {
        states(filter: { ids: $id }) {
            ... on StateI {
                name
            }
            ... on StateNumeric {
                unit {
                    id
                }
            }
            ... on StateSelection {
                selectionOptions {
                    id
                    name
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class Project_Create_Input_StatesGQL extends Apollo.Query<
    Project_Create_Input_StatesQuery,
    Project_Create_Input_StatesQueryVariables
> {
    override document = Project_Create_Input_StatesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProjectEditor_GetAllProjectDocument = gql`
    query projectEditor_getAllProject($page: Int, $count: Int, $filter: ProjectTemplatesFilter) {
        projectTemplatesPage(filter: $filter, page: $page, count: $count) {
            entities {
                id
                name
                projectObjective
                status
                note
                projectGroups {
                    name
                }
            }
            pageInfo {
                pages
                page
                count
                totalCount
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class ProjectEditor_GetAllProjectGQL extends Apollo.Query<
    ProjectEditor_GetAllProjectQuery,
    ProjectEditor_GetAllProjectQueryVariables
> {
    override document = ProjectEditor_GetAllProjectDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StateExportDocument = gql`
    query stateExport {
        stateExport {
            id
            name
            categories
            dataType
            type
            description {
                title
                description
            }
            visibleAtCategory
            defaultQuestion
            references {
                referenceType
                description
            }
            lifeTimeMs
            calculationType
            stateDependencies {
                stateId
            }
            effectTypeText {
                positive
                negative
                neutral
            }
            unit
            valueRange {
                min
                max
                intervals {
                    start
                    end
                    labels
                    widthPercentage
                    criteria {
                        stateId
                        operator
                        value
                    }
                }
            }
            selectionOptions {
                id
                name
            }
            visibleAtDiary
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class StateExportGQL extends Apollo.Query<StateExportQuery, StateExportQueryVariables> {
    override document = StateExportDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const AllStatesCountDocument = gql`
    query allStatesCount($filter: StateFilter, $page: Int, $count: Int) {
        statePage(filter: $filter, page: $page, count: $count) {
            pageInfo {
                totalCount
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class AllStatesCountGQL extends Apollo.Query<AllStatesCountQuery, AllStatesCountQueryVariables> {
    override document = AllStatesCountDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ImportStatesDocument = gql`
    mutation importStates($input: [StateExportInput!]!) {
        importStates(stateExport: $input)
    }
`;

@Injectable({
    providedIn: 'root',
})
export class ImportStatesGQL extends Apollo.Mutation<ImportStatesMutation, ImportStatesMutationVariables> {
    override document = ImportStatesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MutateState_GetAllStatesIdsDocument = gql`
    query MutateState_GetAllStatesIds {
        states {
            __typename
            ... on StateI {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MutateState_GetAllStatesIdsGQL extends Apollo.Query<
    MutateState_GetAllStatesIdsQuery,
    MutateState_GetAllStatesIdsQueryVariables
> {
    override document = MutateState_GetAllStatesIdsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StateForm_UpdateStateDocument = gql`
    mutation stateForm_updateState($input: StateUpdateInput!) {
        updateState(stateInput: $input) {
            ... on StateI {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class StateForm_UpdateStateGQL extends Apollo.Mutation<
    StateForm_UpdateStateMutation,
    StateForm_UpdateStateMutationVariables
> {
    override document = StateForm_UpdateStateDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetAllStatesDocument = gql`
    query getAllStates($filter: StateFilter, $page: Int, $count: Int) {
        statePage(filter: $filter, page: $page, count: $count) {
            pageInfo {
                pages
                page
                count
                totalCount
            }
            entities {
                ... on StateI {
                    id
                    name
                    type
                    dataType
                    defaultQuestion
                    visibleAtCategory
                    visibleAtDiary
                    categories {
                        name
                        description
                    }
                    description {
                        title
                        description
                    }
                    lifeTimeMs
                    references {
                        description
                        referenceType
                    }
                    effectTypeText {
                        positive
                        neutral
                        negative
                    }
                }
                ... on StateNumeric {
                    unit {
                        id
                        name
                        displaynameDE
                    }
                    valueRange {
                        min
                        max
                        intervals {
                            start
                            end
                            labels
                            widthPercentage
                            criteria {
                                stateId
                                operator
                                value
                            }
                        }
                    }
                }
                ... on StateSelection {
                    selectionOptions {
                        id
                        name
                    }
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetAllStatesGQL extends Apollo.Query<GetAllStatesQuery, GetAllStatesQueryVariables> {
    override document = GetAllStatesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateStateDocument = gql`
    mutation createState($input: StateInput!) {
        addState(stateInput: $input) {
            ... on StateI {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateStateGQL extends Apollo.Mutation<CreateStateMutation, CreateStateMutationVariables> {
    override document = CreateStateDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetAllUnitsDocument = gql`
    query getAllUnits {
        units {
            id
            name
            displaynameDE
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetAllUnitsGQL extends Apollo.Query<GetAllUnitsQuery, GetAllUnitsQueryVariables> {
    override document = GetAllUnitsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SearchUnitDocument = gql`
    query searchUnit($input: String!) {
        units(search: $input) {
            id
            name
            displaynameDE
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class SearchUnitGQL extends Apollo.Query<SearchUnitQuery, SearchUnitQueryVariables> {
    override document = SearchUnitDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetAllCategoriesDocument = gql`
    query getAllCategories {
        categories {
            name
            area
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetAllCategoriesGQL extends Apollo.Query<GetAllCategoriesQuery, GetAllCategoriesQueryVariables> {
    override document = GetAllCategoriesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StateCalculationUsagesDocument = gql`
    query stateCalculationUsages($stateId: String!) {
        stateCalculationUsages(stateId: $stateId) {
            ... on StateI {
                id
                name
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class StateCalculationUsagesGQL extends Apollo.Query<
    StateCalculationUsagesQuery,
    StateCalculationUsagesQueryVariables
> {
    override document = StateCalculationUsagesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const Tasks_GetAllTaskDocument = gql`
    query tasks_getAllTask {
        tasks {
            ... on ITask {
                id
                name
                taskType
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class Tasks_GetAllTaskGQL extends Apollo.Query<Tasks_GetAllTaskQuery, Tasks_GetAllTaskQueryVariables> {
    override document = Tasks_GetAllTaskDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const Tasks_GetAllTaskPageDocument = gql`
    query tasks_getAllTaskPage($filter: TaskFilter, $page: Int, $count: Int) {
        taskPage(filter: $filter, page: $page, count: $count) {
            entities {
                ... on ITask {
                    id
                    name
                    note
                    description
                    taskType
                    taskStateValues {
                        overwrittenDefaultQuestion
                        stateId
                        value
                    }
                    references {
                        __typename
                        description
                        referenceType
                    }
                }
                ... on LearningTask {
                    xapiCourseUploadInfo {
                        zipFileName
                        uploadDate
                        __typename
                    }
                }
                ... on CheckItemTask {
                    checkItemTemplates {
                        id
                        title
                        description
                        mandatory
                        checked
                        order
                    }
                }
            }
            pageInfo {
                pages
                page
                count
                totalCount
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class Tasks_GetAllTaskPageGQL extends Apollo.Query<
    Tasks_GetAllTaskPageQuery,
    Tasks_GetAllTaskPageQueryVariables
> {
    override document = Tasks_GetAllTaskPageDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        FeedEvent: [
            'BuddySessionSummaryFeedEvent',
            'DwinCreatedFeedEvent',
            'ProjectCancelledFeedEvent',
            'ProjectFinishedFeedEvent',
            'StateValueCalculatedFeedEvent',
            'StateValueDefinedFeedEvent',
        ],
        FeedEventI: [
            'BuddySessionSummaryFeedEvent',
            'DwinCreatedFeedEvent',
            'ProjectCancelledFeedEvent',
            'ProjectFinishedFeedEvent',
            'StateValueCalculatedFeedEvent',
            'StateValueDefinedFeedEvent',
        ],
        IStateValue: ['CalculatedStateValue', 'DefinedStateValue'],
        ITask: ['CheckItemTask', 'LearningTask', 'StateCollectionTask'],
        State: ['StateDate', 'StateNumeric', 'StateSelection', 'StateString'],
        StateI: ['StateDate', 'StateNumeric', 'StateSelection', 'StateString'],
        StateValue: ['CalculatedStateValue', 'DefinedStateValue'],
        Task: ['CheckItemTask', 'LearningTask', 'StateCollectionTask'],
    },
};
export default result;
